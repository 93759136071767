import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { City, Province } from '../model/TeacherInfo';
import api from '../utils/api';
import { useLoading } from '../context/LoadingContext';
import { SubjectsAndSchools, TeacherForm } from '../model/registration/TeacherForm';
import { VatType } from '../model/VatType';
import TeacherPersonalInfo from '../components/registration/TeacherPersonalInfo';
import InvoiceInfo from '../components/registration/InvoiceInfo';
import TeacherSujectRegistration from '../components/registration/TeacherSubjectRegistration';

type Step = 'personalInfo' | 'subjectSelection' | 'invoiceInfo';

const TeacherRegistration: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [step, setStep] = useState<Step>('personalInfo');
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token, navigate]);

  const [form, setForm] = useState<TeacherForm>({
    token: token || '',
    city_id: 0,
    province_id: 0,
    first_name: '',
    last_name: '',
    subjects_and_schools: [],
    phone: '+39',
    cf: '',
    email: '',
    date_of_birth: '',
    password: '',
    street_name: '',
    street_number: '',
    zip: '',
    iban: '',
    destination_id: '',
    terms_and_conditions: 1,
    vat: '',
    vat_type: VatType.FORFETTARIO_STARTUP,
    invoice_province_id: 0,
    invoice_city_id: 0,
    invoice_street_address: '',
    invoice_street_number: '',
    invoice_zip: '',
  });

  const { register } = useAuth();
  const { setLoading } = useLoading();

  useEffect(() => {
    setLoading(true);
    api
      .get('/province?page_size=200')
      .then((response) => {
        setProvinces(response.data.provinces);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  useEffect(() => {
    if (form.province_id) {
      setLoading(true);
      api
        .get(`/city?province_id=${form.province_id}&page_size=200`)
        .then((response) => {
          setCities(response.data.cities);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [form.province_id, setLoading]);

  const validateFirstName = (firstName: string) => {
    if (!/^[a-zA-Z]{2,}$/.test(firstName)) {
      return 'Nome deve contenere almeno 2 lettere.';
    }
    return '';
  };

  const validateLastName = (lastName: string) => {
    if (!/^[a-zA-Z]{2,}$/.test(lastName)) {
      return 'Cognome deve contenere almeno 2 lettere.';
    }
    return '';
  };

  const validatePhone = (phone: string) => {
    if (!/^\+[0-9]{10,19}$/.test(phone)) {
      return 'Telefono deve essere in formato internazionale.(es. +393331234567)';
    }
    return '';
  };

  const validateCF = (cf: string) => {
    if (!/^[a-zA-Z]{6}\d{2}[a-ehlmpr-tA-EHLMPR-T]{1}\d{2}[a-zA-Z]\d{3}[a-zA-Z]$/.test(cf)) {
      return 'Codice fiscale non valido.';
    }
    return '';
  };

  const validateEmail = (email: string) => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return 'Email non valida.';
    }
    return '';
  };

  const validateDateOfBirth = (dateOfBirth: string) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    if (today.getFullYear() - birthDate.getFullYear() < 18) {
      return 'Devi avere almeno 18 anni.';
    }
    return '';
  };

  const validatePassword = (password: string) => {
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(password)) {
      return 'Password deve contenere almeno 8 caratteri, una maiuscola, una minuscola e un numero.';
    }
    return '';
  };

  const validateIBAN = (iban: string) => {
    if (!/^[A-Z]{2}\d{2}[A-Z]{1,2}\d{1,30}$/.test(iban)) {
      return 'IBAN non valido.';
    }
    return '';
  };

  const validateDestinationID = (destinationId: string) => {
    if (!/^[A-Za-z0-9]{7}$/.test(destinationId)) {
      return 'Codice destinatario deve essere di 7 cifre (numeri o lettere).';
    }
    return '';
  };

  const validateVAT = (vat: string) => {
    if (!/^\d{11}$/.test(vat)) {
      return 'Partita IVA deve essere composta da 11 cifre numeriche.';
    }
    return '';
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let { name, value } = event.target;
    setForm((prev) => {
      if (name === 'cf') {
        value = value.toUpperCase();
      }
      const newForm = { ...prev, [name]: value };
      let error = '';
      switch (name) {
        case 'first_name':
          error = validateFirstName(value);
          break;
        case 'last_name':
          error = validateLastName(value);
          break;
        case 'phone':
          error = validatePhone(value);
          break;
        case 'cf':
          error = validateCF(value);
          break;
        case 'email':
          error = validateEmail(value);
          break;
        case 'date_of_birth':
          error = validateDateOfBirth(value);
          break;
        case 'password':
          error = validatePassword(value);
          break;
        case 'iban':
          error = validateIBAN(value);
          break;
        case 'destination_id':
          error = validateDestinationID(value);
          break;
        case 'vat':
          error = validateVAT(value);
          break;
        default:
          break;
      }
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
      return newForm;
    });
  };

  useEffect(() => {
    const isPersonalInfoComplete =
      form.first_name &&
      form.last_name &&
      form.phone &&
      form.cf &&
      form.email &&
      form.date_of_birth &&
      form.province_id &&
      form.city_id &&
      form.zip &&
      form.street_name &&
      form.street_number &&
      !Object.values(errors).some((error) => error);

    const isSubjectSelectionComplete =
      form.subjects_and_schools.length > 0

    const isInvoiceInfoComplete =
      form.vat &&
      form.vat_type &&
      form.destination_id &&
      form.iban &&
      form.invoice_province_id &&
      form.invoice_city_id &&
      form.invoice_zip &&
      form.invoice_street_address &&
      form.invoice_street_number &&
      !Object.values(errors).some((error) => error);

    if (step === 'personalInfo') {
      setIsDisabled(!isPersonalInfoComplete);
    } else if (step === 'subjectSelection') {
      setIsDisabled(!isSubjectSelectionComplete);
    } else if (step === 'invoiceInfo') {
      setIsDisabled(!isInvoiceInfoComplete);
    }
  }, [form, errors, step]);

  const manageStep = () => {
    if (step === 'personalInfo') {
      setStep('subjectSelection');
    } else if (step === 'subjectSelection') {
      setStep('invoiceInfo');
    } else {
      console.log('form:', form);
      register(form, 'teacher');
    }
  };

  const back = () => {
    if (step === 'subjectSelection') {
      setStep('personalInfo');
    } else if (step === 'invoiceInfo') {
      setStep('subjectSelection');
    }
  };

  const onUpdateSubjectsAndSchools = (subjectsAndSchools: SubjectsAndSchools[]) => {
    console.log('subject e scuole nel padre:', subjectsAndSchools);
    setForm((prev) => ({ ...prev, subjects_and_schools: subjectsAndSchools }));
    console.log('form:', form);
  };

  return (
    <div className="flex flex-col mx-auto gap-y-4 sm:max-w-2xl">
      <h1 className="text-center text-3xl sm:text-2xl font-bold text-fpcred">Registrazione Insegnante</h1>
      {step === 'personalInfo' && (
        <TeacherPersonalInfo
          form={form}
          provinces={provinces}
          cities={cities}
          handleChange={handleChange}
          // onUpdateSubjectsAndSchools={onUpdateSubjectsAndSchools}
          errors={errors}
        />
      )}
      {step === 'subjectSelection' && (
        <>
          <TeacherSujectRegistration
            form={form}
            onUpdateSubjectsAndSchools={onUpdateSubjectsAndSchools}
            initialSubjectsAndSchools={form.subjects_and_schools}
          />
          <button className="bg-fpcred px-4 py-2 rounded-lg text-white " onClick={back}>
            Indietro
          </button>
        </>
      )}
      {step === 'invoiceInfo' && (
        <InvoiceInfo form={form} handleChange={handleChange} errors={errors} />
      )}
      <div className={`flex ${step === 'invoiceInfo' ? 'justify-between' : 'justify-center'} w-full`}>
        {step === 'invoiceInfo' && (
          <button className="bg-fpcred px-4 py-2 rounded-lg text-white " onClick={back}>
            Indietro
          </button>
        )}
        <button
          disabled={isDisabled}
          className="bg-fpcred px-4 py-2 rounded-lg text-white disabled:bg-gray-500 disabled:cursor-not-allowed"
          onClick={manageStep}>
          {step === 'personalInfo' || 'subjectSelection' ? 'Continua' : 'Registrati'}
        </button>
      </div>
    </div>
  );
};

export default TeacherRegistration;
