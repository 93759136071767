import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import { DateSelectArg, EventClickArg, EventInput } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
import useScreenWidth from '../../hooks/useScreenWidth';
import EditTeacherLesson from '../modal/EditTeacherLesson';

interface CalendarProps {
  lessonsEvent: EventInput[];
  availabilitiesEvent: EventInput[];
  isOpenEditTeacherLesson: (event: EventInput) => void;
}

const BookedLessonsCalendar: React.FC<CalendarProps> = ({
  lessonsEvent,
  availabilitiesEvent,
  isOpenEditTeacherLesson,
}) => {
  const [events, setEvents] = useState<EventInput[]>([availabilitiesEvent, lessonsEvent]);
  const [initialView, setInitialView] = useState<string>('timeGridWeek');
  const [selectedEvent, setSelectedEvent] = useState<EventInput | null>(null);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const screenWidth = useScreenWidth();

  const today = new Date(Date.now());
  const start = new Date(new Date().setDate(today.getDate() - 30));
  const maxDate = new Date(new Date().setDate(today.getDate() + 30));

  const handleEventClick = (clickInfo: EventClickArg) => {
    const eventIndex = events.findIndex((event) => event.id === clickInfo.event.id);
    if (clickInfo.event.groupId === 'lesson') {
      setSelectedEvent(events[eventIndex]);
      isOpenEditTeacherLesson(events[eventIndex]);
    }
  };

  useEffect(() => {
    const availabilitiesWithBackground = availabilitiesEvent.map((event) => ({
      ...event,
    }));
    setEvents([...lessonsEvent.map((event) => ({ ...event, groupId: 'lesson' })), ...availabilitiesWithBackground]);
  }, [lessonsEvent, availabilitiesEvent]);

  useEffect(() => {
    if (screenWidth < 1024) {
      setInitialView('timeGridDay');
    } else {
      setInitialView('timeGridWeek');
    }
  }, [screenWidth]);

  const handleDateClick = (arg: any) => {
    if (arg.view.type === 'dayGridMonth' || arg.view.type === 'dayGridYear') {
      let calendarApi = arg.view.calendar;
      calendarApi.changeView('timeGridDay', arg.date);
    }
  };

  const selectAllow = (selectInfo: DateSelectArg) => {
    if (
      lessonsEvent.some(
        (lesson) =>
          selectInfo.start >= new Date(lesson.start_date_time) &&
          selectInfo.end <= new Date(lesson.end_date_time) &&
          selectInfo.end.getTime() - selectInfo.start.getTime() <= 30 * 60 * 1000
      )
    ) {
      return true;
    }
    return false;
  };

  const renderEventContent = (eventInfo) => {
    if (eventInfo.event.title === 'Lezione') {
      return (
        <div className="flex flex-col text-white font-bold">
          <p>
            {eventInfo.event.extendedProps.student.first_name} {eventInfo.event.extendedProps.student.last_name}
          </p>
          <p>
            {eventInfo.event.extendedProps.subject.name} - {eventInfo.event.extendedProps.location}
          </p>
        </div>
      );
    } else if (eventInfo.event.extendedProps.customHtml) {
      return eventInfo.event.extendedProps.customHtml;
    } else {
      return null;
    }
  };

  return (
    <>
      <FullCalendar
        key={initialView}
        eventClick={handleEventClick}
        selectAllow={selectAllow}
        // eventClick={(info: EventClickArg) => manageEventClick(info)}
        locale={itLocale}
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView={initialView}
        headerToolbar={{
          start: 'prev',
          center: 'title',
          end: 'next',
        }}
        editable={false}
        eventDragMinDistance={99}
        selectable={true}
        selectMirror={true}
        weekends={true}
        validRange={{ start: start, end: maxDate }}
        events={events}
        // select={(selectInfo: DateSelectArg) => selectDate(selectInfo)}
        dateClick={handleDateClick}
        allDaySlot={false}
        height={'100%'}
        eventContent={renderEventContent}
        slotMinTime={'07:00:00'}
        slotMaxTime={'22:00:00'}
        selectLongPressDelay={70}
      />
    </>
  );
};

export default BookedLessonsCalendar;
