import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import sidebarLinksData from '../data/sidebarLinks.json';
import { ReactComponent as LogoFPCH } from '../ui/logoFPCH.svg';
import { Link } from 'react-router-dom';
import Icon from '../ui/icons/Icon';
import useAuth from '../hooks/useAuth';
interface sidebarProps {
  pathname: string;
}

interface sidebarLinks {
  name: string;
  subPath: string;
  icon: string;
}

const Sidebar = ({ pathname }: sidebarProps) => {
  const [links, setLinks] = useState<sidebarLinks[]>([]);
  const { t } = useTranslation();
  const { auth, logout } = useAuth();
  
  useEffect(() => {
    if(auth.isFamily){
      setLinks(sidebarLinksData.family);
    }else if(auth.isTeacher){
      setLinks(sidebarLinksData.teacher);
    }
  },[auth.isFamily, auth.isTeacher]);

  return (
    <div className="min-h-screen w-64 bg-white text-black flex flex-col rounded-r-xl">
      <Link to="/" className="w-auto p-4">
        <LogoFPCH className="h-7 lg:h-12 w-auto" />
      </Link>
      <ul className="">
        {links.map((link, index) => {
          const isActive = pathname === link.subPath;
          return (
            <li
              key={index}
              className={`p-2 md:my-8 xl:my-10 ${
                isActive ? 'border-l-4 border-l-fpcred bg-fpcpink text-fpcred' : 'text-gray-500'
              }`}>
              <Link to={link.subPath} className="flex flex-row items-center ">
                <Icon
                  iconName={link.icon}
                  options={{
                    width: 24,
                    height: 24,
                    activeState: isActive,
                  }}
                />
                <span className={'font-semibold ml-2'}>{t('sidebar.' + link.name)}</span>
              </Link>
            </li>
          );
        })}
      </ul>
      {auth.isAuth && (
        <div onClick={logout} className="flex items-center justify-center cursor-pointer">
          <img src="https://icongr.am/material/logout.svg?size=64&color=FF5757" alt="logout-icon" className="w-8 h-8" />
          <span className="text-fpcred font-bold">Esci</span>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
