import React from 'react';
import { useState, useEffect } from 'react';
import { EventInput } from '@fullcalendar/core';
import { useError } from '../../context/ErrorContext';
import { useLoading } from '../../context/LoadingContext';
import api from '../../utils/api';
interface Props {
  selectedEvent: EventInput;
  closeModal: () => void;
  handleEditModal: () => void;
}

const EditTeacherLesson: React.FC<Props> = ({ selectedEvent, closeModal, handleEditModal }) => {
  const { setLoading } = useLoading();
  const { setError } = useError();

  const handleDelete = async () => {
    try {
      setLoading(true);
      await api.delete(`lesson/${selectedEvent.id}`);
      closeModal(); // Chiudi il modal dopo l'eliminazione
    } catch (error) {
      closeModal();
      console.error("Errore durante l'eliminazione della lezione", error);
      setError(true, error.response.data.message, error.response.status);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center">
      <div className="bg-white p-4 mx-auto w-96 h-48 rounded-lg shadow-lg flex flex-col">
        <div className="flex justify-between">
          <h1 className="text-fpcred font-bold text-xl">Informazioni sulla lezione</h1>
          <button onClick={closeModal}>
            <p className="text-fpcred font-bold text-xl">X</p>
          </button>
        </div>
        <div className="flex flex-1 flex-col">
          <div className="flex gap-2">
            <p className="font-bold">Studente:</p>
            <p className="">
              {selectedEvent.extendedProps.student.first_name} {selectedEvent.extendedProps.student.last_name}
            </p>
          </div>
          <div className="flex gap-2">
            <p className="font-bold">Materia:</p>
            <p>{selectedEvent.extendedProps.subject.name}</p>
          </div>
          <div className="flex gap-2">
            <p className="font-bold">Luogo:</p>
            <p>{selectedEvent.extendedProps.location}</p>
          </div>
        </div>
        <div className="flex justify-center gap-4">
          <button className="bg-fpcred text-white font-bold rounded-lg w-1/3 p-2" onClick={handleEditModal}>
            Modifica
          </button>
          <button className="bg-fpcred text-white font-bold rounded-lg w-1/3 p-2" onClick={handleDelete}>
            Cancella
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTeacherLesson;
