interface CardProps {
    title: string;
    onClick?: () => void;
    selected?: boolean;
    imageSrc?: string;  // Aggiungi una prop per l'immagine
  }
  
  export const Card: React.FC<CardProps> = ({ title, selected, onClick, imageSrc }) => (
    <div onClick={onClick} className={`w-[150px] sm:w-56 flex flex-col items-center justify-center gap-y-4 select-none rounded-3xl border-b-4 border-fpcred ${!selected ? "bg-white" : "bg-fpcred text-white"} shadow-lg cursor-pointer transform transition duration-300 hover:scale-105 p-4 sm:p-6 md:p-8`}>
      {imageSrc && <img src={imageSrc} alt={title} className="mb-2 w-24 h-24" />}  {/* Mostra l'immagine */}
      <div className="text-center">
        <p className="font-bold text-lg sm:text-xl md:text-2xl">{title}</p>
      </div>
    </div>
  );
  