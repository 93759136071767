import React from 'react';
import { SchoolCardImg } from '../SchoolCardImg';
import hsSvg from '../../ui/Schools/hs.svg';
import msSvg from '../../ui/Schools/ms.svg';
import psSvg from '../../ui/Schools/ps.svg';

interface SchoolLevelSelectionProps {
  options: string[];
  onNext: (selectedType: string) => void;
  setSelectedSchoolLevel: (selectedSchoolLevel: string) => void;
}

const SchoolLevelSelection: React.FC<SchoolLevelSelectionProps> = ({ onNext, options, setSelectedSchoolLevel }) => {
  const handleSchoolSelect = (school: string) => {
    setSelectedSchoolLevel(school);
    onNext(school);
  };

  const imagesMap = {
    hs: hsSvg,
    ms: msSvg,
    ps: psSvg,
  };
  // Mappa delle opzioni con il testo da mostrare sulle card
  const optionTexts: { [key: string]: string } = {
    ps: 'Scuola Elementare',
    ms: 'Scuola Media',
    hs: 'Scuola Superiore',
    University: 'Università',
  };

  return (
    <div className="flex flex-col items-center justify-center gap-y-4">
      <h2 className="text-2xl font-bold mb-4">Quale scuola frequenta?</h2>
      <div className="flex flex-col md:flex-row gap-4 sm:gap-6 md:gap-8 lg:gap-10 justify-center md:max-w-3xl">
        {options.map((option, index) => (
          <SchoolCardImg
            key={index}
            title={optionTexts[option] || option}
            onClick={() => handleSchoolSelect(option)}
            imageSrc={imagesMap[option]}
            disabled={option === 'University'}
          />
        ))}
      </div>
    </div>
  );
};

export default SchoolLevelSelection;
