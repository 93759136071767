import React, { useState, useEffect } from 'react';
import { ProfileComponent } from '../../pages/teacher/Profile';
import { City, TeacherInfo, Province } from '../../model/TeacherInfo';
import api from '../../utils/api';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';

interface EditProfileProps {
  goTo: (component: ProfileComponent) => void;
}

interface Form {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  cf: string;
  province_id: number;
  city_id: number;
  zip: string;
  street_name: string;
  street_number: string;
}

const EditProfile: React.FC<EditProfileProps> = ({ goTo }) => {
  const [teacherInfo, setTeacherInfo] = useState<TeacherInfo>();
  const [cities, setCities] = useState<City[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [form, setForm] = useState<Form>({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    cf: '',
    province_id: 0,
    city_id: 0,
    zip: '',
    street_name: '',
    street_number: '',
  });
  const { setLoading } = useLoading();
  const { setError } = useError();
  const [initialForm, setInitialForm] = useState<Form | null>(null);
  const [isFormChanged, setIsFormChanged] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get('/teacher')
      .then((response) => {
        const teacher = response.data.user;
        setTeacherInfo(teacher);
        const initialForm = {
          first_name: teacher.first_name,
          last_name: teacher.last_name,
          email: teacher.email,
          phone: teacher.phone,
          cf: teacher.cf,
          province_id: teacher.province.id,
          city_id: teacher.city.id,
          zip: teacher.zip,
          street_name: teacher.street_name,
          street_number: teacher.street_number,
        };
        setForm(initialForm);
        setInitialForm(initialForm);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get('/province?page_size=200')
      .then((response) => {
        setProvinces(response.data.provinces);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (form.province_id) {
      setLoading(true);
      api
        .get(`/city?province_id=${form.province_id}&page_size=200`)
        .then((response) => {
          setCities(response.data.cities);
        })
        .catch((error) => {
          console.error(error);
          setError(true, error.response.data.message, error.response.status);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [form.province_id]);

  useEffect(() => {
    if (initialForm) {
      const hasChanged = Object.keys(form).some((key) => form[key as keyof Form] !== initialForm[key as keyof Form]);
      setIsFormChanged(hasChanged);
    }
  }, [form, initialForm]);

  function onSave() {
    setLoading(true);
    api
      .patch('/teacher', form)
      .then(() => {
        setInitialForm({ ...form });
        setIsFormChanged(false);
      })
      .catch((error) => {
        console.error('Errore durante il salvataggio:', error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: name === 'province_id' || name === 'city_id' ? Number(value) : value,
    }));
  }

  if (!teacherInfo) {
    return null; // Ritorna null se teacherInfo non è ancora stato caricato
  }

  return (
    <div className="flex flex-col gap-y-2 w-full">
      <div className="flex gap-2 ">
        <div className="flex flex-col w-1/2">
          <div className=" text-fpcred font-bold">Nome</div>
          <input
            className="w-full h-8 px-2 border-2 border-black border-opacity-50 rounded-lg"
            name="first_name"
            value={form.first_name}
            type="text"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col w-1/2">
          <div className="text-fpcred font-bold ">Cognome</div>
          <input
            className="w-full h-8 px-2 border-2 border-black border-opacity-50 rounded-lg"
            name="last_name"
            value={form.last_name}
            type="text"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="flex gap-2">
        <div className="flex flex-col w-3/5">
          <div className="text-fpcred font-bold">Email</div>
          <input
            className="w-full border-2 border-black border-opacity-50 rounded-lg h-8 px-2"
            name="email"
            value={form.email}
            type="text"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col w-2/5">
          <div className="text-fpcred font-bold">Telefono</div>
          <input
            className="w-full border-2 border-black border-opacity-50 rounded-lg h-8 px-2"
            name="phone"
            value={form.phone}
            type="text"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="text-fpcred font-bold">Codice fiscale</div>
        <input
          className="border-2 border-black border-opacity-50 rounded-lg h-8 px-2"
          name="cf"
          value={form.cf}
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="flex gap-2">
        <div className="flex flex-col w-1/2">
          <div className="text-fpcred font-bold">Provincia</div>
          <select
            name="province_id"
            value={form.province_id}
            onChange={handleChange}
            className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg h-8">
            <option value="" disabled>
              Seleziona una provincia
            </option>
            {provinces.map((province) => (
              <option key={province.id} value={province.id}>
                {province.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col w-1/2">
          <div className="text-fpcred font-bold">Città</div>
          <select
            name="city_id"
            value={form.city_id}
            onChange={handleChange}
            className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg h-8">
            <option value={0} disabled>
              Seleziona una città
            </option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="text-fpcred font-bold">Indirizzo</div>
        <input
          className=" border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
          name="street_name"
          value={form.street_name}
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="flex gap-2">
        <div className="flex flex-col w-1/2">
          <div className="text-fpcred font-bold">N° Civico</div>
          <input
            className="w-full border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
            name="street_number"
            value={form.street_number}
            type="text"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col w-1/2">
          <div className="text-fpcred font-bold">Cap</div>
          <input
            className="w-full border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
            name="zip"
            value={form.zip}
            type="text"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="flex justify-center p-2">
        <button
          className={`rounded-full p-2 w-1/2 ${
            isFormChanged ? 'bg-fpcred text-white' : 'bg-fpcred text-white opacity-50 cursor-not-allowed'
          }`}
          disabled={!isFormChanged}
          onClick={onSave}>
          Conferma modifiche
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
