import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import { loadSvg } from '../../utils/loadSvg';
import { useError } from '../../context/ErrorContext';
import { useLoading } from '../../context/LoadingContext';
import { motion, AnimatePresence } from 'framer-motion';
import { School as SchoolIcon } from 'lucide-react';
import { School, TeacherInfo } from '../../model/TeacherInfo';

interface ModalProps {
  open: boolean;
  title?: string;
  closeModal: () => void;
  teacherInfo: TeacherInfo;
}

interface Subjects {
  subject: SubjectDetails;
  schools: LevelSchools[];
}

interface SubjectDetails {
  id: number;
  name: string;
}

interface LevelSchools {
  [school_level: string]: {
    [school_type: string]: SchoolItem[];
  };
}

interface SchoolItem {
  id: number;
  name: string;
  level: string;
  school_type: string;
  classes: string[];
}

const levelLabels: { [key: string]: string } = {
  ps: 'scuola elementare',
  ms: 'scuola media',
  hs: 'scuola superiore',
};

const orderedLevels = ['ps', 'ms', 'hs'];

// Aggiunta di tipi di dato per tenere traccia degli elementi selezionati
type SelectedItems = {
  subjectId?: number;
  levels: { [level: string]: boolean };
  schoolTypes: { [level: string]: { [schoolType: string]: boolean } };
  classes: { [schoolId: number]: Set<string> };
};

export interface PatchSchool {
  id: number; // Questo è l'ID dello `school_type`
  classes: string[]; // Solo le classi insegnate
}

export interface PatchSubject {
  id: number;
  schools: PatchSchool[]; // Array dei `school_type` con gli `id` e `classes`
}

export interface PatchTeacherInfo {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  cf: string;
  vat: string;
  date_of_birth: string;
  street_name: string;
  street_number: string;
  zip: string;
  city_id: number;
  province_id: number;
  destination_code: string;
  invoice_prefix: string;
  current_invoice_number: number;
  bio: string;
  iban: string;
  reg_f: string;
  default_warning_time_online: number;
  default_warning_time_home: number;
  default_warning_time_headquarter: number;
  home_lessons_radius_from_headquarter: number;
  home_lessons_radius_from_home: number;
  headquarter_id: number;
  invoice_city_id: number;
  invoice_province_id: number;
  invoice_zip: string;
  invoice_street_number: string;
  invoice_street_address: string;
  invoice_country: string;
  subjects: PatchSubject[]; // Solo `subject_id` e `schools` con `id` e `classes`
}

const AddSubjectModal = ({ open, title, closeModal, teacherInfo }: ModalProps) => {
  const [showModal, setShowModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [subjects, setSubjects] = useState<Subjects[]>([]);
  const { setLoading } = useLoading();
  const { setError } = useError();
  const [images, setImages] = useState<{ [key: string]: string }>({});
  const [selectedSubject, setSelectedSubject] = useState<Subjects | null>(null);
  const [selectedLevel, setSelectedLevel] = useState<string | null>(null);
  const [selectedSchoolType, setSelectedSchoolType] = useState<string | null>(null);
  const [activeSchools, setActiveSchools] = useState<{
    [subjectId: number]: {
      subjectId: number;
      subjectName: string;
      levels: {
        [level: string]: {
          [schoolType: string]: {
            school: School;
            teached_classes: string[];
          }[];
        };
      };
    };
  }>({});

  const handleCloseModal = () => {
    // Resetta gli stati alla loro condizione iniziale
    setSelectedSubject(null);
    setSelectedItems({
      levels: {},
      schoolTypes: {},
      classes: {},
    });
    // Chiama la funzione per chiudere la modale
    closeModal();
  };
  

  const [selectedItems, setSelectedItems] = useState<SelectedItems>({
    levels: {},
    schoolTypes: {},
    classes: {},
  });

  useEffect(() => {
    if (teacherInfo) {
      const activeSchoolsData = getActiveSchools(teacherInfo);
      setActiveSchools(activeSchoolsData);
    }
  }, [teacherInfo]);

  const loadSubjectImages = async (subjectsData: Subjects[]) => {
    const newImages: { [key: string]: string } = {};
    for (const subjectItem of subjectsData) {
      try {
        newImages[subjectItem.subject.name] = await loadSvg(subjectItem.subject.name);
      } catch (error) {
        console.error(`Errore nel caricamento dell'immagine per ${subjectItem.subject.name}:`, error);
      }
    }
    setImages(newImages);
  };

  const getActiveSchools = (teacherInfo: TeacherInfo) => {
    const activeSchools: {
      [subjectId: number]: {
        subjectId: number;
        subjectName: string;
        levels: {
          [level: string]: {
            [schoolType: string]: {
              school: School;
              teached_classes: string[];
            }[];
          };
        };
      };
    } = {};

    teacherInfo.subjects.forEach((subjectInfo) => {
      const subjectId = subjectInfo.subject.id;
      const subjectName = subjectInfo.subject.name;

      activeSchools[subjectId] = {
        subjectId,
        subjectName,
        levels: {},
      };

      Object.entries(subjectInfo.schools).forEach(([level, schoolTypes]) => {
        activeSchools[subjectId].levels[level] = {};

        Object.entries(schoolTypes).forEach(([schoolType, schools]) => {
          activeSchools[subjectId].levels[level][schoolType] = schools.map((school) => ({
            school,
            teached_classes: school.teached_classes,
          }));
        });
      });
    });

    return activeSchools;
  };

  useEffect(() => {
    setLoading(true);
    api
      .get('/subject')
      .then((response) => {
        const data = response.data;
        const subjectsArray: Subjects[] = data.subjects;

        // Ottieni gli ID delle materie già attive
        const activeSubjectIds = new Set(teacherInfo.subjects.map((subject) => subject.subject.id));

        // Filtra le materie già attive
        const filteredSubjects = subjectsArray.filter((subject) => !activeSubjectIds.has(subject.subject.id));

        setSubjects(filteredSubjects);
        loadSubjectImages(filteredSubjects);
      })
      .catch((error) => {
        console.error('Errore durante il caricamento delle materie:', error);
        setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setError, setLoading, teacherInfo.subjects]);

  useEffect(() => {
    if (open) {
      setShowModal(true);
      setTimeout(() => setVisible(true), 10);
    } else {
      setVisible(false);
      const timer = setTimeout(() => setShowModal(false), 500);
      return () => clearTimeout(timer);
    }
  }, [open]);

  const handleSubjectSelect = (subjectItem: Subjects) => {
    setSelectedSubject(subjectItem);
    setSelectedItems((prev) => ({
      ...prev,
      subjectId: subjectItem.subject.id,
      levels: {}, // reset per nuove selezioni
      schoolTypes: {},
      classes: {},
    }));
  };

  const handleLevelSelect = (levelKey: string) => {
    setSelectedItems((prev) => ({
      ...prev,
      levels: {
        ...prev.levels,
        [levelKey]: !prev.levels[levelKey], // Toggle aperto/chiuso
      },
    }));
  };

  const handleSchoolTypeSelect = (levelKey: string, schoolTypeKey: string) => {
    setSelectedItems((prev) => ({
      ...prev,
      schoolTypes: {
        ...prev.schoolTypes,
        [levelKey]: {
          ...(prev.schoolTypes[levelKey] || {}),
          [schoolTypeKey]: !(prev.schoolTypes[levelKey]?.[schoolTypeKey] || false),
        },
      },
    }));
  };

  const handleClassSelect = (schoolId: number, year: string) => {
    setSelectedItems((prev) => {
      const selectedClasses = prev.classes[schoolId] || new Set();
      if (selectedClasses.has(year)) {
        selectedClasses.delete(year);
      } else {
        selectedClasses.add(year);
      }
      return {
        ...prev,
        classes: {
          ...prev.classes,
          [schoolId]: selectedClasses,
        },
      };
    });
  };

  const buildPatchPayload = (): PatchTeacherInfo => {
    // Build the new subject with selected schools and classes
    const selectedSubjectPayload: PatchSubject = {
      id: selectedItems.subjectId!,
      schools: [],
    };

    Object.entries(selectedItems.schoolTypes).forEach(([levelKey, schoolTypes]) => {
      Object.entries(schoolTypes).forEach(([schoolTypeKey, isActive]) => {
        if (!isActive) return;

        const schools = selectedSubject?.schools[levelKey]?.[schoolTypeKey];
        if (!schools) return;

        schools.forEach((school) => {
          const schoolId = school.id;
          const selectedClassesSet = selectedItems.classes[schoolId];
          if (selectedClassesSet && selectedClassesSet.size > 0) {
            selectedSubjectPayload.schools.push({
              id: schoolId,
              classes: Array.from(selectedClassesSet),
            });
          }
        });
      });
    });

    // Map existing subjects to PatchSubject[]
    const existingSubjectsPayload: PatchSubject[] = teacherInfo.subjects.map((subjectInfo) => {
      const subjectId = subjectInfo.subject.id;
      const schools: PatchSchool[] = [];

      Object.entries(subjectInfo.schools).forEach(([levelKey, schoolTypes]) => {
        Object.entries(schoolTypes).forEach(([schoolTypeKey, schoolsArray]) => {
          schoolsArray.forEach((school) => {
            schools.push({
              id: school.id,
              classes: school.teached_classes,
            });
          });
        });
      });

      return {
        id: subjectId,
        schools,
      };
    });

    // Combine existing subjects with the new subject
    const combinedSubjects = [...existingSubjectsPayload, selectedSubjectPayload];

    // Prepare the main payload by explicitly mapping properties
    const patchPayload: PatchTeacherInfo = {
      first_name: teacherInfo.first_name,
      last_name: teacherInfo.last_name,
      email: teacherInfo.email,
      phone: teacherInfo.phone,
      cf: teacherInfo.cf,
      vat: teacherInfo.vat,
      date_of_birth: teacherInfo.date_of_birth,
      street_name: teacherInfo.street_name,
      street_number: teacherInfo.street_number,
      zip: teacherInfo.zip,
      city_id: teacherInfo.city?.id || 0,
      province_id: teacherInfo.province?.id || 0,
      destination_code: teacherInfo.destination_code,
      invoice_prefix: teacherInfo.invoice_prefix,
      current_invoice_number: teacherInfo.current_invoice_number,
      bio: teacherInfo.bio,
      iban: teacherInfo.iban,
      reg_f: teacherInfo.reg_f,
      default_warning_time_online: teacherInfo.default_warning_time_online,
      default_warning_time_home: teacherInfo.default_warning_time_home,
      default_warning_time_headquarter: teacherInfo.default_warning_time_headquarter,
      home_lessons_radius_from_headquarter: teacherInfo.home_lessons_radius_from_headquarter,
      home_lessons_radius_from_home: teacherInfo.home_lessons_radius_from_home,
      headquarter_id: teacherInfo.headquarter?.id || null,
      invoice_city_id: teacherInfo.invoice_city?.id || 0,
      invoice_province_id: teacherInfo.invoice_province?.id || 0,
      invoice_zip: teacherInfo.invoice_zip,
      invoice_street_number: teacherInfo.invoice_street_number,
      invoice_street_address: teacherInfo.invoice_street_address,
      invoice_country: teacherInfo.invoice_country,
      subjects: combinedSubjects, // Include existing and new subjects
    };

    return patchPayload;
  };

  const sendPatchRequest = async () => {
    setLoading(true);
    try {
      const patchPayload = buildPatchPayload();
      console.log('Payload per la PATCH:', JSON.stringify(patchPayload, null, 2));

      const response = await api.patch(`/teacher/${teacherInfo.id}`, patchPayload);
      console.log('Modifica salvata con successo:', response.data);

      handleCloseModal(); // Chiudi la modale dopo il salvataggio
    } catch (error) {
      console.error('Errore durante il salvataggio:', error);
      setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showModal && (
        <motion.div
          className="z-50 fixed inset-0 flex items-end bg-black bg-opacity-50"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
            exit: { opacity: 0 },
          }}
          transition={{ duration: 0.3 }}>
          <motion.div
            className="w-full bg-white rounded-t-lg h-[95%]"
            variants={{
              hidden: { opacity: 0, y: '100%' },
              visible: { opacity: 1, y: 0 },
              exit: { opacity: 0, y: '100%' },
            }}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.4 }}>
            <div className="flex justify-between items-center pt-2 pr-4">
              <h3 className="text-xl text-fpcred font-semibold pl-4">{title}</h3>
              <button onClick={handleCloseModal} className="cursor-pointer text-3xl text-fpcred font-bold">
                <p>X</p>
              </button>
            </div>
            <div className="overflow-auto p-4 h-[95%]">
              <AnimatePresence mode="wait">
                {!selectedSubject && (
                  <motion.div
                    key="subject-list"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -50 }}
                    transition={{ duration: 0.4 }}
                    className="w-full p-4 grid grid-cols-2 md:grid-cols-4 gap-4 overflow-y-auto justify-center">
                    {subjects.map((subjectInfo) => (
                      <motion.div
                        key={subjectInfo.subject.id}
                        onClick={() => handleSubjectSelect(subjectInfo)}
                        className="p-4 border rounded-lg bg-gray-50 shadow-sm h-32 min-w-32 flex flex-col items-center justify-center cursor-pointer"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}>
                        <img
                          src={images[subjectInfo.subject.name]}
                          alt={subjectInfo.subject.name}
                          className="w-16 h-16"
                        />
                        <h3 className="font-semibold text-center">{subjectInfo.subject.name}</h3>
                      </motion.div>
                    ))}
                  </motion.div>
                )}

                {selectedSubject && (
                  <motion.div
                    key="selected-subject"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -50 }}
                    transition={{ duration: 0.4 }}>
                    <button onClick={() => setSelectedSubject(null)} className="mb-4 text-fpcred font-semibold">
                      &larr; Indietro
                    </button>
                    <div className="flex flex-col items-center">
                      <img
                        src={images[selectedSubject.subject.name]}
                        alt={selectedSubject.subject.name}
                        className="w-20 h-20"
                      />
                      <h2 className="text-lg font-semibold mt-2">{selectedSubject.subject.name}</h2>
                    </div>
                    <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                      {orderedLevels
                        .filter((levelKey) => levelKey in selectedSubject.schools)
                        .map((levelKey) => (
                          <div key={levelKey}>
                            <div
                              onClick={() => handleLevelSelect(levelKey)}
                              className="p-4 border rounded-lg shadow-sm flex items-center gap-4 bg-gray-100 cursor-pointer hover:bg-gray-200 transition-colors duration-200">
                              <SchoolIcon className="text-fpcred w-6 h-6" />
                              <h3 className="text-md font-bold text-fpcred">{levelLabels[levelKey]}</h3>
                            </div>

                            {selectedItems.levels[levelKey] && (
                              <div className="pl-8 mt-2">
                                {Object.keys(selectedSubject.schools[levelKey]).map((schoolTypeKey) => (
                                  <div key={schoolTypeKey}>
                                    <div
                                      onClick={() => handleSchoolTypeSelect(levelKey, schoolTypeKey)}
                                      className="p-2 border-l-2 border-fpcred ml-2 mt-1 text-lg font-bold text-gray-700 cursor-pointer hover:bg-gray-50 transition-colors">
                                      {schoolTypeKey}
                                    </div>

                                    {selectedItems.schoolTypes[levelKey]?.[schoolTypeKey] && (
                                      <div className="pl-4 mt-1">
                                        {selectedSubject.schools[levelKey][schoolTypeKey].map((school) => (
                                          <div
                                            key={school.id}
                                            className="p-2 border-l-2 border-fpcred ml-4 mt-1 text-md text-black">
                                            {school.name}
                                            <div className="flex gap-2 mt-1">
                                              {school.classes.map((year) => (
                                                <span
                                                  key={year}
                                                  onClick={() => handleClassSelect(school.id, year)}
                                                  className={`py-2 px-4 text-md bg-gray-200 rounded-md cursor-pointer ${
                                                    selectedItems.classes[school.id]?.has(year) ? 'bg-red-300' : ''
                                                  }`}>
                                                  {year}
                                                </span>
                                              ))}
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                    <div className="flex justify-end mt-4">
                      <button
                        onClick={sendPatchRequest}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition-all">
                        Salva modifiche
                      </button>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        </motion.div>
      )}
    </>
  );
};

export default AddSubjectModal;
