import React from 'react';
import { ProfileComponent } from '../../pages/teacher/Profile';

interface EditProfileProps {
  goTo: (component: ProfileComponent) => void;
}

const ChangePassword: React.FC<EditProfileProps> = ({ goTo }) => {
  function onSave(component: ProfileComponent) {
    goTo(component);
  }

  return (
    <div className="bg-white flex flex-col h-full w-full justify-center p-4 gap-4">
      <div className="font-bold text-center">
        <h2>Modifica password</h2>
      </div>
      <div className="flex flex-col gap-4 items-center">
        <input
          className="border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
          placeholder="Password attuale"
          type="password"
        />
        <input
          className="border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
          placeholder="Nuova password"
          type="password"
        />
        <input
          className="border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
          placeholder="Conferma password"
          type="password"
        />
      </div>

      <div className="flex justify-center">
        <button className="px-6 py-2 bg-fpcred rounded-lg text-white">
          Conferma
        </button>
      </div>
    </div>
  );
};

export default ChangePassword;