import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { useLoading } from '../context/LoadingContext';
import { useError } from '../context/ErrorContext';
import { EventInput } from '@fullcalendar/core';
import Calendario from './Calendar';
import { BookLessonData } from '../model/BookLessonData';
import { BadgeEuro, Calendar, Clock, MapPin, User } from 'lucide-react';
import { motion } from 'framer-motion';

interface EditBookedLessonProps {
  event: EventInput;
}

type Steps = 'selectTeacher' | 'selectDate' | 'selectAvailableTeacher';

const EditBookedLesson: React.FC<EditBookedLessonProps> = ({ event }) => {
  const lessonStart = event.start as Date;
  const lessonEnd = event.end as Date;

  // Calcolo della durata in ore
  const duration = (lessonEnd.getTime() - lessonStart.getTime()) / (1000 * 60 * 60);

  const bookLessonData: BookLessonData = {
    child: event.extendedProps.student,
    subject: event.extendedProps.subject,
    duration: duration,
    location: event.extendedProps.location,
    teacher: event.extendedProps.teacher, // Teacher preso dall'evento inizialmente
    date: {
      start: lessonStart,
      end: lessonEnd,
      startStr: lessonStart.toLocaleString('sv-SE', { timeZone: 'Europe/Rome' }).replace(' ', 'T'), // Formattazione ISO-like senza UTC
      endStr: lessonEnd.toLocaleString('sv-SE', { timeZone: 'Europe/Rome' }).replace(' ', 'T'),
      allDay: false,
      view: {} as any,
      jsEvent: {} as any,
    },
  };

  const [step, setStep] = useState<Steps>('selectTeacher');
  const [lessonData, setLessonData] = useState(bookLessonData);
  const [availableTeachers, setAvailableTeachers] = useState<any>({ exactAvailabilities: [], nearAvailabilities: [] });
  const { setLoading } = useLoading();
  const { setError } = useError();
  const [teachersInfo, setTeachersInfo] = React.useState<any[]>(
    JSON.parse(localStorage.getItem('teachersInfo') || '[]')
  );
  const [isTeacherSelected, setIsTeacherSelected] = useState(false);

  useEffect(() => {
    switch (step) {
      case 'selectTeacher':
        console.log('sono in selectTeacher');
        console.log('bookLessonData:', bookLessonData);
        break;
      case 'selectDate':
        console.log('sono in selectDate');
        break;
      case 'selectAvailableTeacher':
        console.log('sono in selectAvailableTeacher');
        console.log('bookLessonData dopo il calendario:', lessonData);
        fetchAvailableTeachersForSelectedDate(lessonData.date.startStr, lessonData.date.endStr);
        break;
    }
  }, [step]);

  // step === 'selectTeacher'
  const selectCurrentTeacher = () => {
    setLessonData((prevData) => ({ ...prevData, teacher: bookLessonData.teacher }));
    setStep('selectDate');
  };

  const selectOtherTeachers = () => {
    setLessonData((prevData) => ({ ...prevData, teacher: undefined })); // Nessuna preferenza
    setStep('selectDate');
  };

  const handleDateSelect = (selectInfo: any) => {
    const selectedDateStart = new Date(selectInfo.startStr);
    console.log('selectedDateStart:', selectedDateStart);
    const selectedDateEnd = new Date(selectInfo.startStr);
    console.log('selectedDateEnd:', selectedDateEnd);

    selectedDateEnd.setMinutes(selectedDateEnd.getMinutes() + bookLessonData.duration * 60);

    // Ensure the selected dates are in local time
    const selectedDateStartLocal = new Date(
      selectedDateStart.getTime() - selectedDateStart.getTimezoneOffset() * 60000
    );
    const selectedDateEndLocal = new Date(selectedDateEnd.getTime() - selectedDateEnd.getTimezoneOffset() * 60000);

    selectInfo.end = selectedDateEnd;
    selectInfo.endStr = selectedDateEndLocal.toISOString().slice(0, 19).replace('T', ' ');
    selectInfo.startStr = selectedDateStartLocal.toISOString().slice(0, 19).replace('T', ' ');

    setLessonData((prevData) => ({
      ...prevData,
      date: {
        start: selectedDateStart,
        end: selectedDateEnd,
        startStr: selectInfo.startStr,
        endStr: selectInfo.endStr,
        allDay: selectInfo.allDay,
        jsEvent: selectInfo.jsEvent,
        view: selectInfo.view,
      },
    }));
    setStep('selectAvailableTeacher');
  };

  const fetchAvailableTeachersForSelectedDate = async (startStr: string, endStr: string) => {
    setLoading(true);
    try {
      const availabilityRequest = {
        student_id: lessonData.child.id,
        teacher_id: lessonData.teacher ? lessonData.teacher.id : undefined,
        subject_id: lessonData.subject.id,
        location: lessonData.location,
        required_lessons: [{ start_date_time: startStr, end_date_time: endStr }],
      };

      const response = await api.post('availability/search', availabilityRequest);
      const availabilities = response.data.availabilities[0];
      console.log('availabilities: ', availabilities);

      // Carica i teacherInfo già memorizzati in localStorage
      const storedTeachersInfo = localStorage.getItem('teachersInfo');
      let teachersInfo = storedTeachersInfo ? JSON.parse(storedTeachersInfo) : [];

      // Combina le disponibilità esatte e vicine
      const allAvailabilities = [...availabilities.exact_availabilities, ...availabilities.near_availabilities];

      // Trova gli ID degli insegnanti unici
      const uniqueTeacherIdsSet = new Set(allAvailabilities.map((availability: any) => availability.teacher_id));
      const uniqueTeacherIds = Array.from(uniqueTeacherIdsSet);

      // Filtra gli ID degli insegnanti che non sono già memorizzati
      const missingTeacherIds = uniqueTeacherIds.filter(
        (teacherId) => !teachersInfo.some((teacher: any) => teacher.id === teacherId)
      );

      // Ottieni le informazioni degli insegnanti mancanti
      const newTeacherInfosPromises = missingTeacherIds.map(async (teacherId) => {
        const response = await api.get(`family/teacher/${teacherId}`);
        return response.data.teacher;
      });

      const newTeacherInfos = await Promise.all(newTeacherInfosPromises);

      // Aggiorna la lista degli insegnanti e memorizza in localStorage se necessario
      if (newTeacherInfos.length > 0) {
        teachersInfo = [...teachersInfo, ...newTeacherInfos];
        setTeachersInfo(teachersInfo);
        localStorage.setItem('teachersInfo', JSON.stringify(teachersInfo));
      }

      // Imposta gli insegnanti disponibili
      setAvailableTeachers({
        exactAvailabilities: availabilities.exact_availabilities,
        nearAvailabilities: availabilities.near_availabilities,
      });
    } catch (error) {
      console.error(error);
      setError(true, error.response.data.message, error.response.status);
    } finally {
      setLoading(false);
    }
  };

  const confirmLessonUpdate = async () => {
    setLoading(true);
    try {
      const payload = {
        start_date_time: lessonData.date.startStr,
        location: lessonData.location,
        subject_id: lessonData.subject.id,
        teacher_id: lessonData.teacher ? lessonData.teacher.id : undefined,
        student_id: lessonData.child.id,
        size: lessonData.duration,
      };
      console.log('payload per la modifica:', payload);
      const response = await api.put(`lesson/single/${event.id}`, payload);
      console.log('Lesson updated successfully:', response.data);
      // Aggiungi qui eventuali azioni post-modifica, come una notifica di successo o il reindirizzamento
    } catch (error) {
      console.error(error);
      setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status);
    } finally {
      setLoading(false);
    }
  };

  const selectTeacher = (selectedTeacher: any) => {
    setLessonData((prevData) => ({
      ...prevData,
      teacher: selectedTeacher,
    }));
    setIsTeacherSelected(true); // Imposta a true dopo aver selezionato l'insegnante
  };

  function TeacherCard({ teacher, availability, isNearAvailability = false }) {
    console.log('teacher: ', teacher);
    return (
      <div className="flex flex-col border-2 rounded-lg p-4 bg-white mb-4 hover:shadow-lg hover:border-fpcred transition-shadow duration-300">
        <div className="flex gap-4">
          <div className="flex flex-col justify-start w-full md:w-1/3 h-full">
            <div className="h-full md:max-h-[60%]">
              <User className="w-full h-full" />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row gap-2 mb-2">
              <p className="text-xl font-semibold">{teacher.first_name}</p>
              <p className="text-xl font-semibold">{teacher.last_name}</p>
            </div>
            {isNearAvailability && (
              <div className="bg-[#f3e8e8] text-[#d10c33] font-bold px-4 py-2 text-center md:max-w-[20%] rounded-full text-sm">
                {new Date(availability.start_date_time).toLocaleTimeString('it-IT', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                /
                {new Date(availability.end_date_time).toLocaleTimeString('it-IT', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </div>
            )}
            <div className="mb-4">
              <p className="text-base">{teacher.bio ? teacher.bio : 'Nessuna bio disponibile'}</p> {/* Mostra la bio */}
            </div>
            <div>
              <button
                onClick={() => selectTeacher(teacher)}
                className="bg-[#d10c33] px-4 py-2 rounded-xl text-white hover:bg-[#d10c33]/90 transition-colors duration-300">
                Seleziona insegnante
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full p-4">
      <h2 className="text-2xl font-semibold mb-4">Modifica lezione</h2>

      <div className="bg-gray-100 p-4 rounded-lg shadow mb-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <span className="font-semibold">Materia:</span>
            <span>{lessonData.subject.name}</span>
          </div>
          <div className="flex items-center gap-2">
            <span className="font-semibold">Location:</span>
            <span>{lessonData.location}</span>
          </div>
        </div>
      </div>

      {step === 'selectTeacher' && (
        <div>
          <h3 className="text-xl mb-4">Seleziona un'opzione per l'insegnante</h3>
          <div className="flex flex-col gap-4">
            <button
              onClick={selectCurrentTeacher}
              className="p-3 bg-green-500 text-white rounded-lg hover:bg-green-600">
              Mantieni insegnante attuale ({lessonData.teacher.first_name} {lessonData.teacher.last_name})
            </button>
            <button onClick={selectOtherTeachers} className="p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
              Nessuna preferenza
            </button>
          </div>
        </div>
      )}

      {step === 'selectDate' && (
        <div className="h-full">
          <Calendario bookLessonData={lessonData} onDateSelect={handleDateSelect} />
        </div>
      )}

      {step === 'selectAvailableTeacher' && availableTeachers && (
        <div className="container mx-auto max-w-7xl overflow-auto">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Lista Insegnanti (a destra) */}
            <div className="lg:col-span-2">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}>
                <div className="bg-fpcwhite rounded-lg p-2 md:p-6 shadow-md">
                  <h2 className="text-2xl font-bold text-[#d10c33] mb-4">Insegnanti Disponibili</h2>
                  <div className="space-y-6">
                    <div>
                      <h3 className="text-xl font-semibold mb-2">Disponibilità per l'orario richiesto</h3>
                      <div className="space-y-4 max-h-96 overflow-y-auto">
                        {availableTeachers.exactAvailabilities.map((availability, index) => {
                          const teacher = teachersInfo.find((teacher) => teacher.id === availability.teacher_id);
                          return teacher ? (
                            <TeacherCard key={index} teacher={teacher} availability={availability} />
                          ) : null;
                        })}
                      </div>
                    </div>

                    <div>
                      <h3 className="text-xl font-semibold mb-2">Disponibilità vicino all'orario richiesto</h3>
                      <div className="space-y-4 max-h-96 overflow-y-auto">
                        {availableTeachers.nearAvailabilities.map((availability, index) => {
                          const teacher = teachersInfo.find((teacher) => teacher.id === availability.teacher_id);
                          return teacher ? (
                            <TeacherCard key={index} teacher={teacher} availability={availability} isNearAvailability />
                          ) : null;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
          {isTeacherSelected && (
            <div className="flex justify-center mt-4">
              <button onClick={confirmLessonUpdate} className="bg-fpcred text-white font-bold rounded-lg w-1/3 p-2">
                Conferma modifica
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EditBookedLesson;
