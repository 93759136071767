import React, { useEffect, useState } from 'react';
import { SubjectsAndSchools, TeacherForm } from '../../model/registration/TeacherForm';
import { City, Province } from '../../model/TeacherInfo';
import api from '../../utils/api';
import { useLoading } from '../../context/LoadingContext';

interface Props {
  form: TeacherForm;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  cities: City[];
  provinces: Province[];
  // onUpdateSubjectsAndSchools: (subjectsAndSchools: SubjectsAndSchools[]) => void;
  errors: { [key: string]: string };
}

const TeacherPersonalInfo: React.FC<Props> = ({
  form,
  handleChange,
  // onUpdateSubjectsAndSchools,
  cities,
  provinces,
  errors,
}) => {
  return (
    <div className="bg-white p-4 flex flex-col rounded-lg shadow-lg gap-4">
      <p className="text-center text-xl font-bold text-fpcred">Informazioni personali</p>
      <form className="flex flex-col gap-2">
        <div className="lg:flex-row flex flex-col gap-2">
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Nome</span>
            <input
              type="text"
              className={`border ${errors.first_name ? 'border-red-500' : 'border-gray-400'} rounded-lg p-2`}
              name="first_name"
              value={form.first_name}
              onChange={handleChange}
            />
            {errors.first_name && <span className="text-red-500">{errors.first_name}</span>}
          </label>
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Cognome</span>
            <input
              type="text"
              className={`border ${errors.last_name ? 'border-red-500' : 'border-gray-400'} rounded-lg p-2`}
              name="last_name"
              value={form.last_name}
              onChange={handleChange}
            />
            {errors.last_name && <span className="text-red-500">{errors.last_name}</span>}
          </label>
        </div>
        <div className="lg:flex-row flex flex-col gap-2">
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Telefono</span>
            <input
              type="text"
              className={`border ${errors.phone ? 'border-red-500' : 'border-gray-400'} rounded-lg p-2`}
              name="phone"
              value={form.phone}
              onChange={handleChange}
            />
            {errors.phone && <span className="text-red-500">{errors.phone}</span>}
          </label>
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Codice fiscale</span>
            <input
              type="text"
              className={`border ${errors.cf ? 'border-red-500' : 'border-gray-400'} rounded-lg p-2`}
              name="cf"
              value={form.cf}
              onChange={handleChange}
            />
            {errors.cf && <span className="text-red-500">{errors.cf}</span>}
          </label>
        </div>
        <div className="lg:flex-row flex flex-col gap-2">
          <label className="flex flex-col gap-2 w-full lg:w-3/4">
            <span className="font-bold text-xl sm:text-lg">Email</span>
            <input
              type="text"
              className={`border ${errors.email ? 'border-red-500' : 'border-gray-400'} rounded-lg p-2`}
              name="email"
              value={form.email}
              onChange={handleChange}
            />
            {errors.email && <span className="text-red-500">{errors.email}</span>}
          </label>
          <label className="flex flex-col gap-2 w-full lg:w-3/4">
            <span className="font-bold text-xl sm:text-lg">Password</span>
            <input
              type="password"
              className={`border ${errors.password ? 'border-red-500' : 'border-gray-400'} rounded-lg p-2`}
              name="password"
              value={form.password}
              onChange={handleChange}
            />
            {errors.password && <span className="text-red-500">{errors.password}</span>}
          </label>
          <label className="flex flex-col gap-2 w-full lg:w-1/4">
            <span className="font-bold text-xl sm:text-lg">Data di nascita</span>
            <input
              type="date"
              className={`border ${errors.date_of_birth ? 'border-red-500' : 'border-gray-400'} rounded-lg p-2`}
              name="date_of_birth"
              value={form.date_of_birth}
              onChange={handleChange}
            />
            {errors.date_of_birth && <span className="text-red-500">{errors.date_of_birth}</span>}
          </label>
        </div>
        <div className="text-xl font-bold text-fpcred text-center">
          <p>Dati Domicilio</p>
        </div>
        <div className="lg:flex-row flex flex-col gap-2">
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Provincia</span>
            <select
              name="province_id"
              value={form.province_id}
              onChange={handleChange}
              className="w-full border border-gray-400 rounded-lg px-2 text-lg py-2">
              <option value={0} disabled>
                Seleziona una provincia
              </option>
              {provinces.map((province) => (
                <option key={province.id} value={province.id}>
                  {province.name}
                </option>
              ))}
            </select>
          </label>
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Città</span>
            <select
              disabled={cities.length === 0}
              name="city_id"
              value={form.city_id}
              onChange={handleChange}
              className="w-full border border-gray-400 rounded-lg px-2 text-lg py-2">
              <option value={0} disabled>
                Seleziona una città
              </option>
              {cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </label>
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Cap</span>
            <input
              className="border border-gray-400 rounded-lg p-2"
              name="zip"
              value={form.zip}
              type="text"
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="flex gap-2">
          <label className="flex flex-col gap-2 w-3/4">
            <span className="font-bold text-xl sm:text-lg">Indirizzo</span>
            <input
              type="text"
              className="border border-gray-400 rounded-lg p-2"
              name="street_name"
              value={form.street_name}
              onChange={handleChange}
            />
          </label>
          <label className="flex flex-col gap-2 w-1/4">
            <span className="font-bold text-xl sm:text-lg">Numero</span>
            <input
              type="text"
              className="border border-gray-400 rounded-lg p-2"
              name="street_number"
              value={form.street_number}
              onChange={handleChange}
            />
          </label>
        </div>
        {/* <div className="flex flex-col gap-2">
          <h2 className="text-fpcred text-xl font-bold text-center">Seleziona le scuole e le materie</h2>
          {schools && (
            <SubjectAndSchoolSelection
              data={schools}
              subjects_and_schools={form.subjects_and_schools}
              onUpdate={onUpdateSubjectsAndSchools}
            />
          )}
        </div> */}
      </form>
    </div>
  );
};

export default TeacherPersonalInfo;
