import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import React from 'react';
import { useEffect, useRef, useState } from 'react';
import interactionPlugin from '@fullcalendar/interaction';
import { EventInput } from '@fullcalendar/core';
import useScreenWidth from '../../hooks/useScreenWidth';
import { useError } from '../../context/ErrorContext';
import { useLoading } from '../../context/LoadingContext';
import api from '../../utils/api';
import DaysSlider from '../DaySlider';
import '../../styles/calendarStyles.css';

type Props = {
  event: EventInput;
  handleSlotClick: (start: Date, end: Date) => void;
};

const EditBookedLessonTeacherCalendar: React.FC<Props> = ({ event, handleSlotClick }) => {
  const screenWidth = useScreenWidth();
  const calendarRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [timeSlots, setTimeSlots] = useState<any[]>([]);
  const [lessons, setLessons] = useState<any[]>([]);

  const lesson = event as EventInput;

  console.log('Lezione:', lesson);

  const today = new Date(Date.now());
  const start = new Date(new Date().setDate(today.getDate() - 30));
  const maxDate = new Date(new Date().setDate(today.getDate() + 30));

  const handleDateChange = (date) => {
    setSelectedDate(date); // Aggiorna la data selezionata

    // Assicurati che il riferimento a FullCalendar esista prima di chiamare getApi
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(date); // Vai alla nuova data
    } else {
      console.error('FullCalendar reference is null');
    }
  };

  // Carica le lezioni prenotate dall'API
  useEffect(() => {
    api.get('/lesson').then((response) => {
      const loadedLessons = response.data.lessons.map((lesson) => ({
        start: new Date(lesson.start_time),
        end: new Date(lesson.end_time),
      }));
      console.log('Lezioni prenotate:', loadedLessons);
      setLessons(loadedLessons);
    });
  }, []);

  const generateTimeSlots = () => {
    const stepMs = 30 * 60000; // 30 minuti in millisecondi
    const slots = [];
    const today = new Date();
    const lessonDurationMs = (lesson.size || 1) * 60 * 60000; // Durata della lezione in millisecondi

    const currentMonth = today.getMonth();

    // Calcola l'orario di partenza: due ore dall'ora esatta attuale
    const initialStartTime = new Date(today);
    initialStartTime.setHours(today.getHours() + 2, 0, 0, 0); // Due ore dall'ora corrente

    // Inizia il loop da oggi
    let date = new Date(initialStartTime);
    date.setHours(0, 0, 0, 0); // Resetta l'ora per iniziare dalla mezzanotte ogni giorno

    while (date.getMonth() === currentMonth) {
      // Calcola l'orario di partenza per il giorno corrente
      const startTime = date.getDate() === today.getDate() ? initialStartTime.getTime() : date.getTime();
      const dayEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 24, 0, 0, 0).getTime(); // Fine giornata

      let currentTime = startTime;

      while (currentTime < dayEnd) {
        const start = new Date(currentTime);
        const end = new Date(currentTime + stepMs);
        const displayEnd = new Date(currentTime + lessonDurationMs); // Fine visualizzata basata su lesson.size

        // Verifica se lo slot coincide con una lezione prenotata
        const isOccupied = lessons.some((lesson) => {
          return lesson.start.getTime() === start.getTime() && lesson.end.getTime() === displayEnd.getTime();
        });

        slots.push({
          id: `${date.toISOString()}-${currentTime}`,
          start,
          end, // Ogni slot dura 30 minuti
          displayEnd,
          title: isOccupied
            ? 'Lezione occupata'
            : `${start.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })} - ${displayEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`,
          classNames: isOccupied
            ? ['occupied-event', 'bg-gray-400', 'cursor-not-allowed']
            : ['available-event', 'bg-fpcred', 'cursor-pointer'],
          editable: !isOccupied,
        });

        currentTime += stepMs; // Passa al prossimo slot da mezz'ora
      }

      // Incrementa al giorno successivo
      date.setDate(date.getDate() + 1);
      date.setHours(0, 0, 0, 0); // Resetta l'ora a mezzanotte
    }

    setTimeSlots(slots);
  };

  useEffect(() => {
    generateTimeSlots();
  }, [lessons]);

  const handleEventClick = (info: any) => {
    const start = info.event.start as Date;
    const end = info.event.extendedProps.displayEnd as Date;

    if (start && end) {
      console.log('Cliccato:', start, end);
      handleSlotClick(start, end);
    } else {
      console.error('Slot selezionato non valido');
    }
  };

  return (
    <>
      {screenWidth < 1024 && <DaysSlider selectedDate={selectedDate} onDateChange={handleDateChange} />}
      <FullCalendar
        key={screenWidth < 1024 ? 'mobile' : 'desktop'}
        locale={'it'}
        ref={calendarRef}
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView={screenWidth < 1024 ? 'timeGridDay' : 'timeGridWeek'}
        initialDate={selectedDate}
        headerToolbar={
          screenWidth >= 1024
            ? {
                start: 'title', // Mostra "Ottobre" su desktop
                center: '',
                end: 'prev,today,next',
              }
            : { start: 'title', center: '', end: 'prev,next' }
        } // Nasconde l'headerToolbar su mobile
        titleFormat={{ month: 'long' }} // Mostra il nome del mese per l'header
        dayHeaderContent={
          screenWidth >= 1024
            ? (args) => {
                const date = args.date;
                const dayNumber = date.getDate();
                const weekdayName = date.toLocaleDateString(undefined, { weekday: 'short' });
                return (
                  <div className="custom-day-header p-2 rounded-full">
                    <div className="text-xl">{dayNumber}</div>
                    <div>{weekdayName}</div>
                  </div>
                );
              }
            : false
        } // Nasconde il nome del giorno su mobile
        editable={false}
        selectable={false}
        events={timeSlots}
        eventClick={handleEventClick}
        selectMirror={true}
        weekends={true}
        validRange={{ start: start, end: maxDate }}
        allDaySlot={false}
        height={'100%'}
        slotDuration={'00:30:00'}
        slotMinTime={'07:00:00'}
        slotMaxTime={'24:00:00'}
        eventContent={(arg) => (
          <div className="event-title">
            {arg.event.title} {/* Mostra solo il titolo */}
          </div>
        )}
      />
    </>
  );
};

export default EditBookedLessonTeacherCalendar;
