import React, { useEffect, useState } from 'react';
import { ProfileComponent } from '../../pages/teacher/Profile';
import { City, Province, TeacherInfo } from '../../model/TeacherInfo';
import api from '../../utils/api';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';

interface Props {
  goTo: (component: ProfileComponent) => void;
}

interface Form {
  vat: string;
  invoice_city_id: number;
  invoice_province_id: number;
  invoice_street_address: string;
  invoice_street_number: string;
  invoice_zip: string;
  iban: string;
}

const EditTaxInformation: React.FC<Props> = ({ goTo }) => {
  const [teacherInfo, setTeacherInfo] = useState<TeacherInfo>();
  const [cities, setCities] = useState<City[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [form, setForm] = useState<Form>({
    vat: '',
    invoice_city_id: 0,
    invoice_province_id: 0,
    invoice_street_address: '',
    invoice_street_number: '',
    invoice_zip: '',
    iban: '',
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [initialForm, setInitialForm] = useState<Form | null>(null);
  const { setLoading } = useLoading();
  const { setError } = useError();

  useEffect(() => {
    setLoading(true);
    api
      .get('/teacher')
      .then((response) => {
        console.log(response.data.user);
        const teacher = response.data.user;
        setTeacherInfo(teacher);
        const formData = {
          vat: teacher.vat,
          invoice_city_id: teacher.invoice_city.id,
          invoice_province_id: teacher.invoice_province.id,
          invoice_street_address: teacher.invoice_street_address,
          invoice_street_number: teacher.invoice_street_number,
          invoice_zip: teacher.invoice_zip,
          iban: teacher.iban,
        };
        setForm(formData);
        setInitialForm(formData);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get('/province?page_size=200')
      .then((response) => {
        setProvinces(response.data.provinces);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (form.invoice_province_id) {
      setLoading(true);
      api
        .get(`/city?province_id=${form.invoice_province_id}&page_size=200`)
        .then((response) => {
          setCities(response.data.cities);
        })
        .catch((error) => {
          console.error(error);
          setError(true, error.response.data.message, error.response.status);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [form.invoice_province_id]);

  useEffect(() => {
    checkFormValidity();
  }, [form, initialForm]);

  const checkFormValidity = () => {
    if (!initialForm) {
      setIsDisabled(true);
      return;
    }
    const isFormValid =
      form.vat && form.invoice_city_id && form.invoice_street_address && form.invoice_street_number && form.invoice_zip;

    const isFormChanged =
      form.vat !== initialForm.vat ||
      form.invoice_city_id !== initialForm.invoice_city_id ||
      form.invoice_province_id !== initialForm.invoice_province_id ||
      form.invoice_street_address !== initialForm.invoice_street_address ||
      form.invoice_street_number !== initialForm.invoice_street_number ||
      form.invoice_zip !== initialForm.invoice_zip ||
      form.iban !== initialForm.iban;

    setIsDisabled(!isFormValid || !isFormChanged);
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setForm((prev) => ({ ...prev, invoice_city_id: Number(event.target.value) }));
  };

  const handleProvinceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setForm((prev) => ({ ...prev, invoice_province_id: Number(event.target.value) }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const onSave = () => {
    setLoading(true);
    api
      .patch('/teacher', form)
      .then(() => {
        setInitialForm(form);
      })
      .catch((error) => {
        console.error('Errore durante il salvataggio:', error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!teacherInfo) {
    return null;
  }

  return (
    <div className="flex flex-col w-full gap-y-2">
      <div className="flex gap-2">
        <div className="flex flex-col w-3/4">
          <div className="text-fpcred font-bold">Partita Iva</div>
          <input
            className="w-full border-2 border-black border-opacity-50 rounded-lg h-8 px-2"
            name="vat"
            value={form.vat}
            type="text"
            onChange={handleChange}
            maxLength={11}
          />
        </div>
        <div className="flex flex-col w-1/4">
          <div className="text-fpcred font-bold">Cap</div>
          <input
            className="w-full border-2 border-black border-opacity-50 rounded-lg h-8 px-2"
            name="invoice_zip"
            value={form.invoice_zip}
            type="text"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex flex-col">
        <div className="text-fpcred font-bold">IBAN</div>
        <input
          className="w-full border-2 border-black border-opacity-50 rounded-lg h-8 px-2"
          name="iban"
          value={form.iban}
          type="text"
          onChange={handleChange}
          maxLength={27}
        />
      </div>

      <div className="flex gap-2">
        <div className="w-1/2 flex flex-col">
          <div className="text-fpcred font-bold">Provincia</div>
          <select
            name="invoice_province_id"
            value={form.invoice_province_id}
            onChange={handleProvinceChange}
            className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg h-8">
            <option value="" disabled>
              Seleziona una provincia
            </option>
            {provinces.map((province) => (
              <option key={province.id} value={province.id}>
                {province.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-1/2 flex flex-col">
          <div className="text-fpcred font-bold">Città</div>
          <select
            name="invoice_city_id"
            value={form.invoice_city_id}
            onChange={handleCityChange}
            className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg h-8">
            <option value="" disabled>
              Seleziona una città
            </option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex gap-2">
        <div className="flex flex-col w-3/4">
          <div className="text-fpcred font-bold">Indirizzo</div>
          <input
            className="w-full border-2 border-black border-opacity-50 rounded-lg h-8 px-2"
            name="invoice_street_address"
            value={form.invoice_street_address}
            type="text"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col w-1/4">
          <div className="text-fpcred font-bold">Numero</div>
          <input
            className="w-full border-2 border-black border-opacity-50 rounded-lg h-8 px-2"
            name="invoice_street_number"
            value={form.invoice_street_number}
            type="text"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex justify-center">
        <button
          className="bg-fpcred text-white rounded-lg p-2 w-1/2 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={onSave}
          disabled={isDisabled}>
          Conferma modifiche
        </button>
      </div>
    </div>
  );
};

export default EditTaxInformation;
