// utils/loadSvg.ts
export const loadSvg = async (name: string): Promise<string> => {
    try {
      const svg = await import(`../ui/Materie/${name}Deselected.svg`);
      return svg.default;
    } catch (error) {
      console.error(`Impossibile caricare l'immagine per ${name}:`, error);
      return '';
    }
  };
  