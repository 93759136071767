import React, { useEffect, useRef } from 'react';




const DaysSlider = ({ selectedDate, onDateChange }) => {
    const sliderRef = useRef(null);
    const today = new Date(); // Ottieni il giorno corrente senza ore, minuti, secondi
    today.setHours(0, 0, 0, 0);
  
    useEffect(() => {
      const selectedElement = sliderRef.current.querySelector('.selected');
      if (selectedElement && sliderRef.current) {
        const sliderWidth = sliderRef.current.offsetWidth;
        const itemOffsetLeft = selectedElement.offsetLeft;
        const itemWidth = selectedElement.offsetWidth;
  
        sliderRef.current.scroll({
          left: itemOffsetLeft - (sliderWidth / 2) + (itemWidth / 2),
          behavior: 'smooth',
        });
      }
    }, [selectedDate]);
  
    const daysAroundSelected = (selectedDate) => {
      const days = [];
      for (let i = -3; i <= 3; i++) {
        const day = new Date(selectedDate);
        day.setDate(selectedDate.getDate() + i);
        days.push(day);
      }
      return days;
    };
  
    const daysOfWeek = daysAroundSelected(selectedDate);
  
    return (
      <div className="flex justify-center p-2 overflow-hidden">
        <div className="days-slider flex justify-center p-2" ref={sliderRef}>
          {daysOfWeek.map((day) => {
            const isSelected = day.toDateString() === selectedDate.toDateString();
            const isBeforeToday = day < today; // Confronta con il giorno corrente
  
            return (
              <div
                key={day.toDateString()}
                className={`day-item flex flex-col justify-center font-bold ${isSelected ? 'selected text-2xl' : ''} ${
                  isBeforeToday ? 'disabled' : 'cursor-pointer'
                }`}
                onClick={() => !isBeforeToday && onDateChange(day)} // Evita click su giorni disabilitati
              >
                <div>{day.getDate()}</div>
                <div>{day.toLocaleDateString(undefined, { weekday: 'short' })}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  export default DaysSlider;