import React, { useState, useEffect } from 'react';
import { Card } from '../Card'; // Assumendo l'esistenza di questo componente
import { Subject } from '../../model/registration/SchoolSelection';

// Funzione per caricare dinamicamente gli SVG basandosi sul nome della materia
const loadSvg = async (name: string): Promise<string> => {
  try {
    const svg = await import(`../../ui/Materie/${name}Deselected.svg`);
    return svg.default; // Restituisci il percorso dell'immagine
  } catch (error) {
    console.error(`Impossibile caricare l'immagine per ${name}:`, error);
    return ''; // Ritorna una stringa vuota se non è possibile caricare l'immagine
  }
};

interface SubjectsSelectionProps {
  options: Subject[];
  onSubjectsSelection: () => void;
  setSelectedSchoolSubjects: (selectedSchoolSubjects: string[]) => void;
}

const SubjectsSelection: React.FC<SubjectsSelectionProps> = ({ options, onSubjectsSelection, setSelectedSchoolSubjects }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [images, setImages] = useState<{ [key: string]: string }>({}); // Stato per le immagini

  useEffect(() => {
    // Carica dinamicamente le immagini per tutte le materie quando il componente è montato
    const loadImages = async () => {
      const newImages: { [key: string]: string } = {};
      for (const option of options) {
        newImages[option.name] = await loadSvg(option.name); // Carica l'immagine per ogni materia
      }
      setImages(newImages); // Aggiorna lo stato con le immagini
    };

    loadImages();
  }, [options]); // Esegui ogni volta che le opzioni cambiano

  const handleSelection = (option: Subject) => {
    const isSelected = selectedOptions.find(o => o === option.name);
    if (isSelected) {
      setSelectedOptions(selectedOptions.filter(o => o !== option.name));
    } else {
      setSelectedOptions([...selectedOptions, option.name]);
    }
  };

  const next = () => {
    if (selectedOptions.length === 0) {
      return;
    }
    onSubjectsSelection();
    setSelectedSchoolSubjects(selectedOptions);
  };

  return (
    <div className='flex flex-col items-center justify-center'>
      <h2 className="text-2xl font-bold mb-4">Di quali materie ha bisogno?</h2>
      <div className="flex flex-wrap gap-4 sm:gap-6 md:gap-8 lg:gap-10 justify-center md:max-w-3xl">
        {options.map((option, index) => (
          <Card
            key={index}
            title={option.name}
            onClick={() => handleSelection(option)}
            selected={!!selectedOptions.find(o => o === option.name)}
            imageSrc={images[option.name]}
          />
        ))}
      </div>
      <div className='flex items-center justify-end'>
        <button
          disabled={selectedOptions.length === 0}
          onClick={() => next()}
          className="mt-4 bg-fpcred hover:bg-red text-white font-bold py-2.5 px-8 rounded-xl disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Procedi
        </button>
      </div>
    </div>
  );
};

export default SubjectsSelection;
