import React, { useState } from 'react';

interface InputFieldProps {
  id: string;
  name: string;
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  required?: boolean;
  error?: string;
  autocomplete?: string;
}

export const InputField: React.FC<InputFieldProps> = ({
  id,
  name,
  type,
  placeholder,
  value,
  onChange,
  className = '',
  required = false,
  error = '',
  autocomplete = 'off',
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`relative flex flex-col ${className}`}>
      <input
        required={required}
        id={id}
        name={name}
        type={type === 'password' && showPassword ? 'text' : type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="rounded-xl border-2 p-2"
        autoComplete={autocomplete}
      />
      {type === 'passwordRegister' && (
        <button
          type="button"
          className="absolute right-4 top-1/2 transform -translate-y-1/2"
          onClick={toggleShowPassword}
        >
          {showPassword ? 'Nascondi' : 'Mostra'}
        </button>
      )}
      {error && <span className="text-red-500 border-2">{error}</span>}
    </div>
  );
};
